<template>
  <cs-page class="cs-page-user">
    <div class="d-flex" style="height: 100%">
      <div class="page-left bg-white">
        <div class="user-box text-center">
          <a-avatar :src="userInfo.headUrl" />
          <p>{{ userInfo.realName }}</p>
        </div>
        <a-menu
          mode="inline"
          :default-selected-keys="defaultSelectedKeys"
          class="border-0"
        >
          <a-menu-item @click="menuClick('/user/index')">
            <i class="anticon">
              <cs-icon name="icon-shouye" size="14" />
            </i>
            <span>首页</span>
          </a-menu-item>
          <a-menu-item
            @click="menuClick('/user/order')"
            v-if="userInfo.accountType === 1"
          >
            <i class="anticon">
              <cs-icon name="icon-dingdanguanli" size="14" />
            </i>
            <span>订单管理</span>
          </a-menu-item>
          <a-menu-item
            @click="menuClick('/user/ocrService')"
            v-if="userInfo.accountType === 1"
          >
            <i class="anticon">
              <cs-icon name="icon-OCRshibie" size="14" />
            </i>
            <span>OCR服务</span>
          </a-menu-item>
          <a-menu-item
            @click="menuClick('/user/company')"
            v-if="userInfo.accountType !== 0"
          >
            <i class="anticon">
              <cs-icon name="icon-qiyexinxi" size="14" />
            </i>
            <span>企业信息</span>
          </a-menu-item>
          <a-menu-item @click="menuClick('/user/setting')">
            <i class="anticon">
              <cs-icon name="icon-zhanghaoshezhi" size="14" />
            </i>
            <span>账号设置</span>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="page-right">
        <slot></slot>
      </div>
    </div>
  </cs-page>
</template>
<script>
// userInfo.accountType: 0=普通账号，1=管理员账号，2=未关联企业
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
    defaultSelectedKeys() {
      return [this.$route.path];
    },
  },
  created() {},
  methods: {
    menuClick(path) {
      this.$router.push(path).catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.cs-page-user {
  /deep/ .main-body {
    min-height: 750px !important;
    height: calc(100vh - 145px);
    margin-top: 20px;
    padding: 0 40px;
  }
  .page-left {
    width: 174px;
    .user-box {
      height: 196px;
      background-image: url(../../../../public/img/bg-center.png);
      background-size: cover;
      .ant-avatar {
        width: 64px;
        height: 64px;
        border: 1px solid @primary-color;
        border-radius: 50%;
        margin-top: 50px;
      }
      p {
        font-size: 16px;
        color: #333333;
        word-break: break-all;
        padding: 15px;
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
      .iconfont {
        margin-top: -5px;
        position: relative;
      }
    }
    .ant-menu-item-selected {
      background-color: rgba(0, 164, 151, 0.1);
      color: @primary-color;
      width: 100%;
    }
    .ant-menu-item,
    .ant-menu-item-selected {
      &::after {
        left: 0;
        border-color: @primary-color;
        right: auto;
        transition: none;
      }
    }
  }
  .page-right {
    margin-left: 20px;
    flex: 1;
  }
}
</style>